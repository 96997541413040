<script setup lang="ts">
const services = ref([
  {
    title: 'Webentwicklung',
    description:
      'Wir entwickeln Webseiten und Webanwendungen, die auf Ihre Bedürfnisse zugeschnitten sind.',
    icon: 'tabler:world-www'
  },
  {
    title: 'App-Entwicklung',
    description: 'Wir entwickeln native Apps für iOS und Android.',
    icon: 'tabler:apps'
  },

  {
    title: 'Schnittstellen',
    description:
      'Wir entwickeln leistungsfähige Schnittstellen und Middleware, die Ihre Systeme miteinander verbinden.',
    icon: 'tabler:api'
  },
  {
    title: 'Hosting',
    description: 'Wir hosten Ihre Software auf unseren Servern.',
    icon: 'tabler:server'
  },
  {
    title: 'Cross-Plattform',
    description: 'Wir entwickeln plattformübergreifende Apps für iOS, Android und Web.',
    icon: 'tabler:apps'
  },
  {
    title: 'Prozessautomatisierung',
    description: 'Sie machen immer wieder die gleichen Schritte? Wir automatisieren Ihre Prozesse.',
    icon: 'tabler:automation'
  },

  {
    title: 'Proof of Concept (PoC) & MVP',
    description:
      'Sie haben eine Idee und möchten sie schnell umsetzen? Wir entwickeln Prototypen und MVPs.',
    icon: 'tabler:progress-check'
  },
  {
    title: 'Optimierung',
    description:
      'Wir optimieren bereits entwickelte Software und machen sie schneller und effizienter.',
    icon: 'tabler:device-desktop-cog'
  },
  {
    title: 'Beratung',
    description: 'Wir beraten Sie in allen Fragen rund um das Thema Softwareentwicklung.',
    icon: 'tabler:help'
  },
  {
    title: 'KI & Machine Learning',
    description:
      'Wir entwickeln KI- und Machine-Learning-Modelle, die Ihr Unternehmen unterstützen.',
    icon: 'tabler:ai'
  },
  {
    title: 'Cloud Computing',
    description: 'Wir entwickeln skalierbare Cloud-Lösungen für Ihr Unternehmen.',
    icon: 'tabler:cloud'
  },
  {
    title: 'Individualsoftware',
    description:
      'Sie benötigen eine individuelle Softwarelösung? Wir entwickeln Software, die auf Ihre Bedürfnisse zugeschnitten ist.',
    icon: 'tabler:puzzle'
  }
])

defineComponent({
  name: 'ServicesComponent'
})
</script>

<template>
  <div class="bg-gradient text-secondary-content">
    <div class="container mx-auto px-8 py-20 md:py-36">
      <ClientOnly fallback-tag="div">
        <h2 class="text-2xl text-center md:text-3xl lg:text-4xl font-bold pb-6">
          <RoughNotation :is-show="true" type="highlight">
            <span class="text-accent-content">Unsere Leistungen</span>
          </RoughNotation>
        </h2>
        <template #fallback>
          <h2 class="text-xl text-center lg:text-4xl font-bold pb-6">Unsere Leistungen</h2>
        </template>
      </ClientOnly>
      <div
        class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mt-12 px-8 md:px-0 max-w-6xl mx-auto"
      >
        <ServiceCell
          v-for="service in services"
          :key="service.title"
          :title="service.title"
          :description="service.description"
          :icon="service.icon"
          data-aos="fade-up"
        />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.bg-gradient {
  background: linear-gradient(to bottom, #14213d, #14213d, #18284a);
}
</style>
