<script setup lang="ts">
const properties = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="bg-cell-gradient hover-border px-6 py-10 rounded-md aspect-square">
    <div class="text-center">
      <Icon :name="properties.icon" class="text-accent w-10 h-10" />
    </div>
    <h3 class="text-xl font-bold pt-4 hyphens-auto" lang="de">
      {{ properties.title }}
    </h3>
    <p class="text-sm pt-2 hyphens-auto" lang="de">
      {{ properties.description }}
    </p>
  </div>
</template>

<style scoped lang="css">
.bg-cell-gradient {
  background: linear-gradient(40deg, #213766, #18284a);
}

.hover-border {
  outline: 0.5px solid #213766;
  outline-offset: -1px;
}

.hover-border:hover {
  outline: 2px solid #fca311;
  outline-offset: -2px;
}
</style>
